import { navigate } from '@reach/router'
import { paths } from 'config'
import { Footer } from 'sections'
import { Button, Layout, Section, Typography } from 'shared/components'
import { LogoVertical } from 'shared/svg'

const NotFoundPage = () => (
  <Layout footer={<Footer />}>
    <Section
      style={{
        minHeight: 'calc(100vh - 350px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoVertical
        style={{ height: 200, margin: '0 auto', display: 'block' }}
      />
      <br />
      <Typography variant="body1" align="center" gutterBottom>
        Nie znaleziono strony o żądanym adresie.
      </Typography>
      <Button onClick={() => navigate(paths.index)}>
        Wróć na stronę główną
      </Button>
    </Section>
  </Layout>
)

export default NotFoundPage
